import React, { useEffect } from 'react'
import { Button, Col, Container, Row} from 'react-bootstrap'
import "../scss/solution.scss"
import { AnimationOnScroll } from 'react-animation-on-scroll'
import Slider from 'react-slick'
import { solution_data } from './../data/solution'

import IMAGESOLUTION from './../assets/solution/image-solutions.png'

const Solution = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0, 
      behavior: 'instant'
    });
  }, [])

  return(
    <div className='solution-wrap'>
      <div className='bg-header-without-box-dot explore relative'>
        <div className='box1' />
        <div className='box2' />
        <div className='box3' />
        <Container className='fade-in-text'>
          <div className='row pt-5 pb-5'>
            <div className='col-md-7 col-sm-12 pb-5 relative'>
              <div className='text-center-mobile'><strong className='text-brand'>EIS TECHNOLOGY SOLUTIONS & PRODUCTS</strong></div>
              <div className='header text-center-mobile'>Exhibit a Diverse Range Of Breadth and Depth in Their Offerings</div>
              <p className='mt-4 text-center-mobile' style={{textAlign:"justify"}}>In the contemporary business landscape, there is a noticeable trend wherein both business customers and consumers exhibit a growing inclination towards self-service interactions with organizations. This preference is notably channeled through the seamless interfaces of web and mobile applications.</p>
              <p className='mt-3 text-center-mobile' style={{textAlign:"justify"}}>These digital platforms not only personify convenience but also provide an avenue for accessing real-time data at the discretion of customers, thereby elevating the overall user experience.</p>
              <p className='mt-3 text-center-mobile' style={{textAlign:"justify"}}>At a broad level, EIS solutions & products encompass a comprehensive suite of features designed to cater to the businesses’ needs.</p>
              <p className='mt-3 text-center-mobile' style={{textAlign:"justify"}}>Ultimately, the breadth and depth of EIS technology solutions & products reflect the complexity and diversity of the industry’s landscape, with solutions tailored to address different aspects of the industry. Organizations often deploy a combination of these products to create a comprehensive posture.</p>
            </div>
            <div className='col-md-5 col-sm-12'>
              <img src={IMAGESOLUTION} className='image-solution' alt=''/>
            </div>
          </div>
        </Container>
      </div>

      <div className="bg-pink mt-2 pt-2 pb-2">
        <Container>
          <div className='subheader text-center-mobile text-center'>Our Arsenal of Technology Solutions & Products:</div>
        </Container>
      </div>

      {
        solution_data.map((item, index) => {
          return(
            <div key={index} className={(index+1)%2===0?'bg-pink mt-2 pt-5 pb-5':''}>
              <Container className={(index+1)%2!==0?'pt-5 pb-5':''}>
                <div className='text-center-mobile'><strong className='text-brand'>{item.title}</strong></div>        
                {
                  item.subtitle1 && <div className='subheader text-center-mobile'>{item.subtitle1}</div>
                }
                {
                  item.description1 && <div className="mt-4 text-center-mobile" dangerouslySetInnerHTML={{__html: item.description1}}/>
                }

                <Row className='mt-3' style={{alignItems:'center'}}>
                  <Col xs={12} md={4} className='mb-4'>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft" duration={.8} offset={0} animatePreScroll={true}>
                      {
                        item.subtitle2 && <div className='subheader text-center-mobile'>{item.subtitle2}</div>
                      }

                      {
                        item.description2 && <div className="mt-4 text-center-mobile" dangerouslySetInnerHTML={{__html: item.description2}}/>
                      }
                    </AnimationOnScroll>
                  </Col>
                  <Col xs={12} md={8} className='mb-4 slick-same-height'>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" duration={.8} offset={0} animatePreScroll={true}>
                      <Slider
                        {...{
                          dots: true,
                          infinite: true,
                          autoplaySpeed: 3000,
                          slidesToShow: 3,
                          slidesToScroll: 3,
                          autoplay: true,
                          speed: 1000
                        }}
                      >
                        {
                          item.items.map((row, index) => {
                            return(
                              <div key={'item-'+index} className='p-1'>
                                <div className='box bg-white d-flex flex-column'>
                                  <div className='flex-fill'>
                                    <div className='text-center'><strong>{row.title}</strong></div>
                                    <div className='partner'><img src={row.image} alt=""/></div>
                                  </div>

                                  <div className='text-center mt-2'>
                                    <a className='relative pointer' href={`/solutions-products/${row.slug}`} >
                                      <Button variant='brand'>See More</Button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }

                        {
                          item.items.length === 2 && <div key={'item-'+index} className='p-1'></div>
                        }
                        {
                          item.items.length === 1 && <div key={'item-'+index} className='p-1'></div>
                        }
                        {
                          item.items.length === 1 && <div key={'item-'+index} className='p-1'></div>
                        }
                      </Slider>
                    </AnimationOnScroll>
                  </Col>
                </Row>
              </Container>
            </div>
          )
        })
      }
    </div>
  )
}

export default Solution