import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import BannerHerro from '../components/BannerHero'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import category1 from './../assets/category/1.png'
import category2 from './../assets/category/2.png'
import category3 from './../assets/category/3.png'
import category4 from './../assets/category/4.png'
import category5 from './../assets/category/telco.png'
import category6 from './../assets/category/cyber-icon.png'

import exp from './../assets/exp.png'
import arrow from './../assets/arrow-right.png'
import obi from './../assets/obi.png'
import download from './../assets/download.png'

import solution1 from './../assets/solution/1.png'
import solution2 from './../assets/solution/2.png'
import solution3 from './../assets/solution/3.png'
import solution4 from './../assets/solution/4.png'
import solution5 from './../assets/solution/5.png'
import solution11 from './../assets/solution/11.png'

import image1 from './../assets/partner/Neural.png'
import image2 from './../assets/partner/Liferay.png'
import image3 from './../assets/partner/Orca.png'
import image4 from './../assets/partner/Telin.png'
import image5 from './../assets/partner/Myson.png'
import image6 from './../assets/partner/logo-orca.png'
import image7 from './../assets/partner/logo-sobot.png'

import client1 from './../assets/client/1.png'
import client2 from './../assets/client/2.png'
import client3 from './../assets/client/3.png'
import client4 from './../assets/client/4.png'
import client5 from './../assets/client/5.png'
import client6 from './../assets/client/6.png'
import client7 from './../assets/client/7.png'
import client8 from './../assets/client/8.png'
import client9 from './../assets/client/9.png'
import client10 from './../assets/client/10.png'
import client11 from './../assets/client/11.png'
import client12 from './../assets/client/12.png'
import client13 from './../assets/client/13.png'
import client14 from './../assets/client/14.png'
import client15 from './../assets/client/15.png'
import client16 from './../assets/client/16.png'
import client17 from './../assets/client/17.png'
import client18 from './../assets/client/18.png'

import c27001 from './../assets/27001.png'
import c9001 from './../assets/9001.png'
import noname from './../assets/solution/noname.png'
import proofpoint from './../assets/solution/proofpoint.png'
import ecouncil from './../assets/solution/ecouncil.png'
import djarum from './../assets/use-cases/djarum.jpg'

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from 'react-router-dom';

const settings = {
  dots: true,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  speed: 1000,
  arrows: false
}

const business = [
  {
    icon: category6,
    title: "Cyber Security",
    short_desc: "It is an ongoing process that demands constant vigilance, investment, and collaboration to mitigate the risks associated with an ever-evolving digital landscape."
  },
  {
    icon: category1,
    title: "Advertising & Customer Engagement",
    short_desc: "We make your brand relatable and meaningful through our customer engagement & advertising platform."
  },
  {
    icon: category2,
    title: "Outsourcing & Talent Management",
    short_desc: "Providing professional manpower services for sales promotion and marketing purposes using technology."
  },
  {
    icon: category3,
    title: "e-Commerce",
    short_desc: "Building solution to enhance your e-commerce results from acquisition, performance data, to retention."
  },
  {
    icon: category4,
    title: "Tenant Management System",
    short_desc: "Helps real estate professionals using technology to manage and serve their tenants, including rental property owners, landlords, and property managers."
  },
  {
    icon: category5,
    title: "Telco Solutions",
    short_desc: "Providing high quality project management, systems integration, system design and network implementation services."
  }
]

const Home = () => {
  const navigate = useNavigate();


  return(
    <div className='wrap-hidden'>
      <div className='bg-header relative'>
        <div className='box-dot'/>
        <Container className='fade-in-text'>
          <div className='pt-5 pb-4 text-center header'>Flexible, Agile On Developing Your <span className='text-brand'>Digital Solutions</span></div>
          <div className='pb-5 header-desc'>Irrespective of your business scale, whether it's retail, small/medium enterprises, or large corporations, we are dedicated to propelling your digital transformation and fostering growth. Our suite of technology products, services, and solutions is tailored to accelerate your journey towards enhanced efficiency and innovation.</div>
          <div className='text-center pb-2'>
            <a className='relative pointer' href="/contact-us" >
              <Button variant='brand'>Let's Connect</Button>
            </a>
          </div>
        </Container>
        <BannerHerro/>
      </div>
      <Container className='mt-5 pb-5 wrap-hidden'>
        <Row style={{alignItems:'center'}}>
          <Col xs={12} md={4}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft" duration={.8} offset={0} animatePreScroll={true}>
              <div className='subheader text-center-mobile'>Our Innovative Partners</div>
            </AnimationOnScroll>
          </Col>
          <Col xs={12} md={8}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" duration={.8} offset={0} animatePreScroll={true}>
              <Slider
                {...{
                  dots: false,
                  infinite: true,
                  autoplaySpeed: 3000,
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  autoplay: true,
                  speed: 1000
                }}
              >
                <div className='partner'><img src={image6} alt=""/></div>
                <div className='partner'><img src={noname} alt=""/></div>
                <div className='partner'><img src={proofpoint} alt=""/></div>
                <div className='partner'><img src={ecouncil} alt=""/></div>
                <div className='partner'><img src={image7} alt=""/></div>
                <div className='partner'><img src={image1} alt=""/></div>
                <div className='partner'><img src={image2} alt=""/></div>
                <div className='partner'><img src={image4} alt=""/></div>
                <div className='partner'><img src={image5} alt=""/></div>
              </Slider>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
      <Container className='mt-5 category'>
        <Row>
          <Col xs={12} md={12} lg={12} className='mb-4'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={.5} offset={100}>
              <div className='text-center-mobile'><strong className='text-brand'>OUR BUSINESS</strong></div>
              <div className='header mt-2 text-center-mobile'>Business Focus Category</div>
              <p className='mt-4 text-justify text-center-mobile'>
                We strive for providing enterprise solutions that meet latest industry standard & certification, to further excel our client digital & technology advancement in global marketplace.
                <br/><br/>
                EIS has built various enterprise solution that can fulfill business needs and help customer to have more traction and company revenue. Starting in the telco solution back in 2013, we now expanding our product, services and solutions into various categories:
              </p>
            </AnimationOnScroll>
          </Col>
          {
            business.map((item, index) => {
              return(
                <Col key={"business-"+index} xs={12} md={6} lg={4} className='mb-4 pointer' onClick={()=>navigate("solutions-products")}>
                  <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn" duration={.5} delay={0+(100*index)} offset={100} style={{height:'100%'}}>
                    <div className='box hover-zoom bg-white'>
                      <Row>
                        <Col xs={'auto'} className="mb-3">
                          <img className='btn-category' src={item.icon} alt=""/>
                        </Col>
                        <Col xs md={12}>
                          <div className='title'>{item.title}</div>
                          <p className='mt-2'>{item.short_desc}</p>
                        </Col>
                      </Row>
                    </div>
                  </AnimationOnScroll>
                </Col>
              )
            })
          }
        </Row>
      </Container>

      <div className='exp'>
        <Container>
          <Row style={{alignItems:'center'}}>
            <Col xs={12} md={5} className="hover-zoom">
              <AnimationOnScroll animateOnce={true} animateIn="animate__rollIn" duration={1} offset={100}>
                <img src={exp} alt="" className='hover-rotate'/>
              </AnimationOnScroll>
            </Col>
            <Col xs={12} md={7}>
              <div className='header text-center-mobile'>EIS Digital Experience Product (DxP)</div>
              <p className='mt-3 text-center-mobile'>Transformative content and reliable organizational data source to improve business operations and customer journey.</p>
              <div className='text-center-mobile'>
                <a className='relative pointer' href="/solutions-products" >
                  <Button variant='brand mt-4'>Read More</Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className='mt-5'>
        <Row style={{alignItems:'center'}}>
          <Col xs={12} md={9}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1} offset={100}>
              <div className='text-center-mobile'><strong className='text-brand'>OUR SOLUTION</strong></div>
              <div className='header text-center-mobile mt-2'>Growth to become Digital Accelerator</div>
              <p className='text-center-mobile mt-3'>In the contemporary business landscape, there is a noticeable trend wherein both business customers and consumers exhibit a growing inclination towards self-service interactions with organizations. This preference is notably channeled through the seamless interfaces of web and mobile applications. These digital platforms not only personify convenience but also provide an avenue for accessing real-time data at the discretion of customers, thereby elevating the overall user experience.</p>
            </AnimationOnScroll>
          </Col>
          <Col xs={12} md={3}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn" duration={1} offset={100}>
              <a className='relative pointer' href="/solutions-products" >
                <img className='arrow' src={arrow} alt="" />
              </a>
            </AnimationOnScroll>
          </Col>
        </Row>
        <div className='solution'>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={1} offset={0}>
            <Row className='mt-4'>
              {
                business.map((item, index) => (
                  <Col key={'our-solution-'+index} xs={6} md={4} lg={2} className='mb-4'>
                    <div className='box bg-white'>
                      <img src={item.icon} alt=""/>
                      <div className='mt-3 text-center' style={{fontSize:10, fontWeight:"bold"}}>{item.title}</div>
                    </div>
                  </Col>
                ))
              }
            </Row>
            <div className='text-center mt-2 mobile mb-5'>
              <a className='relative pointer' href="/solutions-products" >
                <Button variant='brand'>See More</Button>
              </a>
            </div>
          </AnimationOnScroll>
        </div>
      </Container>
      <div className='bg-pink mt-2 pt-5 pb-5 studies'>
        <Container>
          <Row style={{alignItems:'center'}}>
            <Col xs={12} md={4}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1} offset={100}>
                <div className='header text-center-mobile'>Our Case Studies</div>
              </AnimationOnScroll>
            </Col>
            <Col xs={12} md={5}>
              
            </Col>
            <Col xs={12} md={3}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn" duration={1} offset={100}>
                <a className='relative pointer' href='/use-cases'>
                  <img className='arrow' src={arrow} alt="" />
                </a>
              </AnimationOnScroll>
            </Col>
          </Row>
          <AnimationOnScroll animateOnce={true} animateIn="animate__backInRight" duration={1} offset={0}>
            <Row className='mt-5' style={{alignItems:'center'}}>
              <Col xs={12} md={4}>
                <div className='relative'>
                  <img src={djarum} className="img-studies" alt="" />
                  {/* <img src={download} className="download pointer hover-zoom" alt="" /> */}
                </div>
                <div className='text-center mt-2'><b>Djarum</b></div>
              </Col>
              <Col xs={12} md={5}>
                <div className="title">The Challenge</div>
                <div className='text-center-mobile mt-2'>
                  <ul>
                    <li>How to control and monitor effectively every activity in the field carried out by the Sales Team, Canvassers, SPG/SPB, Merchandisers and Surveyors.</li>
                    <li>Almost 85% of their work is very vulnerable to fraud and human error.</li>
                    <li>Need an integrated system that is detailed and real-time, and to reduce the vulnerability.</li>
                  </ul>
                </div>
                <div className='mt-5 text-center-mobile'>
                  <strong>The Solution</strong>
                </div>
                <p className='mt-2 text-center-mobile'>Phoenix Application as an Automation Intelligence Services.</p>
              </Col>
            </Row>
            <div className='text-center mt-5 mobile'>
              <a className='relative pointer' href='/use-cases'>
                <Button variant='brand'>See More</Button>
              </a>
            </div>
          </AnimationOnScroll>
        </Container>
      </div>
      <Container className='pt-5 pb-5'>
        <div className='text-center'><strong className='text-brand'>OUR CLIENT</strong></div>
        <div className='header text-center'>Our Valuable Customers</div>

        <Slider {...settings}>
          <div className='customers mb-4'>
            <Row style={{alignItems:'center'}} className="mt-2">
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.5} offset={0}>
                  <img src={client1} alt="" />
                </AnimationOnScroll>
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.5} delay={100} offset={0}>
                  <img src={client2} alt="" />
                </AnimationOnScroll>
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.5} delay={200} offset={0}>
                  <img src={client3} alt="" />
                </AnimationOnScroll>
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.5} delay={300} offset={0}>
                  <img src={client4} alt="" />
                </AnimationOnScroll>
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.5} delay={500} offset={0}>
                  <img src={client5} alt="" />
                </AnimationOnScroll>
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.5} delay={500} offset={0}>
                  <img src={client6} alt="" />
                </AnimationOnScroll>
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.5} delay={600} offset={0}>
                  <img src={client7} alt="" />
                </AnimationOnScroll>
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.5} delay={700} offset={0}>
                  <img src={client8} alt="" />
                </AnimationOnScroll>
              </Col>
            </Row>
          </div>
          <div className='customers mb-4'>
            <Row style={{alignItems:'center'}} className="mt-2">
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                <img src={client9} alt="" />
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client10} alt="" />
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client11} alt="" />
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client12} alt="" />
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client13} alt="" />
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client14} alt="" />
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client15} alt="" />
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client16} alt="" />
              </Col>
            </Row>
          </div>
          <div className='customers mb-4'>
            <Row style={{alignItems:'center'}} className="mt-2">
              <Col xs={12} md={4} lg={3}/>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client17} alt="" />
              </Col>
              <Col xs={6} md={4} lg={3} className="mt-5 text-center">
                  <img src={client18} alt="" />
              </Col>
              <Col xs={12} md={4} lg={3}/>
            </Row>
          </div>
        </Slider>
      </Container>
      <div className='bg-pink explore relative'>
        <Container className='pt-5 pb-5'>
          <div className='header mt-2 text-center-mobile text-center'>Our Certification</div>
          <div className='row mt-5 justify-content-center'>
            <div className='col-sm-6 col-md-3 text-center'>
              <img src={c27001} width="90%"/>
              <div className='mt-3 iso-title'>ISO/IEC 27001:2022</div>
              <div className='iso-desc'>Information Security Management System standard</div>
              <div className='iso-no'>Certificate No: ID55622E</div>
            </div>
            <div className='col-sm-6 col-md-3 text-center'>
              <img src={c9001} width="90%"/>
              <div className='mt-3 iso-title'>ISO 9001:2015</div>
              <div className='iso-desc'>Quality Management System standard</div>
              <div className='iso-no'>Certificate No: ID55622A</div>
            </div>
          </div>
        </Container>
      </div>
      <div className='explore relative'>
        <div className='box1' />
        <div className='box2' />
        <div className='box3' />
        <Container className='pt-5 pb-5'>
          <div className='text-center header mt-5' style={{maxWidth:500, margin: 'auto'}}>Don’t be shy to explore more of our tailored digital solutions</div>
          <div className='text-center mt-5 mb-5'>
            <a className='relative pointer' href="/contact-us" >
              <Button variant="brand">Explore More</Button>
            </a>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Home