import React, { useEffect } from 'react'
import { Container} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { solution_detail } from '../data/solution'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import arrow from './../assets/arrow-right.png'

const SolutionDetail = () => {
  const { slug } = useParams()

  useEffect(() => {
    window.scrollTo({
      top: 0, 
      behavior: 'instant'
    });
  }, [])

  return(
    <div className='solution-wrap'>
      <div className='bg-header-without-box-dot pt-5 pb-3'>
        <Container className='fade-in-text'>
          <AnimationOnScroll animateOnce={true} animateIn="animate__bounceIn" duration={1} offset={100}>
            <a className='relative pointer d-flex flex-row align-items-center gap-3 back-wrapper mb-5' href='/solutions-products' >
              <img src={arrow} alt="" />
              <span>Solutions & Products</span>
            </a>
          </AnimationOnScroll>
          <div className='text-center'>
            <img src={solution_detail[slug]?.image} style={{width:"30%"}} alt=""/>
          </div>
          <div className='text-center-mobile mt-3'><strong className='text-brand'>{solution_detail[slug]?.category}</strong></div>
          <div className='header text-center-mobile'>{solution_detail[slug]?.title}</div>
        </Container>
      </div>
      <Container className='fade-in-text pb-5'>
        <div className="mt-2" dangerouslySetInnerHTML={{__html: solution_detail[slug]?.content}}/>
      </Container>
    </div>
  )
}

export default SolutionDetail