import orca from './../assets/solution/orca.png'
import noname from './../assets/solution/noname.png'
import proofpoint from './../assets/solution/proofpoint.png'
import ecouncil from './../assets/solution/ecouncil.png'
import mediacartz from './../assets/solution/Mediacartz.png'
import sobot from './../assets/solution/Sobot.png'
import phoenix from './../assets/solution/Phoenix.png'
import wais from './../assets/solution/Wais.png'
import myson from './../assets/solution/MySon.png'
import logo from './../assets/logo.png'

export const solution_data = [
  {
    "title": "CYBER SECURITY",
    "subtitle1": "Embark on a Secure Journey with EIS, Today!",
    "description1": "<p>At EIS, we believe in a collaborative approach to cybersecurity. We work closely with you, understanding your unique needs and threats, then tailor a comprehensive solution that fits your budget and business goals. We don't just sell products; we become your trusted security partner, providing ongoing support and guidance to ensure your long-term cybersecurity success.</p>",
    "subtitle2": "EIS: Your Cyber Cavalry in the Digital Frontier",
    "description2": "<p>We're not just vendors; we're your security partners, hand-in-hand with industry leaders like Orca Security, NoName, Proofpoint, and EC-Council.</p><br/><p>Our comprehensive solutions, tailored to your unique digital landscape, protecting startups and behemoths alike. Whether you're navigating the cloud's uncharted territories or defending your web empire, EIS shields your business, ensuring its uninterrupted success.</p>",
    "items": [
      {
        "title": "Conquer the Multi-cloud Maze ",
        "image": orca,
        "slug": "orca-security"
      },
      {
        "title": "Securing Your APIs with Confidence",
        "image": noname,
        "slug": "noname"
      },
      {
        "title": "Email Security & Web Secuirty",
        "image": proofpoint,
        "slug": "proofpoint"
      },
      {
        "title": "Navigating the Labyrinth of Indonesian Data Privacy",
        "image": ecouncil,
        "slug": "ecouncil"
      }
    ]
  },
  {
    "title": "ADVERTISING & CUSTOMER ENGAGEMENT",
    "subtitle1": "",
    "description1": "",
    "subtitle2": "",
    "description2": "<p>The significance of customer full-lifecycle service lies in its ability to create a cohesive and positive experience for customers at every stage of their journey with a business. By offering comprehensive support from initial engagement to post-purchase interactions, businesses can build trust, foster loyalty, and enhance overall customer satisfaction.</p>",
    "items": [
      {
        "title": "Mediacartz",
        "image": mediacartz,
        "slug": "mediacartz"
      },
      {
        "title": "Sobot",
        "image": sobot,
        "slug": "sobot"
      }
    ]
  },
  {
    "title": "OUTSOURCING & TALENT MANAGEMENT",
    "subtitle1": "",
    "description1": "",
    "subtitle2": "",
    "description2": "<p>Outsourcing task management systems provide businesses with a streamlined and efficient approach to handling various responsibilities by delegating tasks to external service providers. These systems leverage technology to facilitate seamless communication, collaboration, and tracking of delegated assignments, ensuring that both the outsourcing company and the external team are on the same page regarding project progress and milestones.</p>",
    "items": [
      {
        "title": "Phoenix",
        "image": phoenix,
        "slug": "phoenix"
      },
      {
        "title": "Wais",
        "image": wais,
        "slug": "wais"
      }
    ]
  },
  {
    "title": "E-COMMERCE",
    "subtitle1": "Product Catalogue & POS System",
    "description1": "<p>The integration of an e-commerce platform with a Point of Sale (POS) system has become essential for modern retail businesses seeking a seamless and omni-channel shopping experience. This synergy enables businesses to manage both online and offline sales from a centralized system, streamlining inventory management, order processing, and customer data.</p><p>&nbsp;</p><p>With a synchronized e-commerce and POS system, customers can enjoy consistent product availability and pricing across various channels, promoting brand loyalty. Moreover, businesses benefit from real-time insights into sales, inventory levels, and customer behavior, allowing for data-driven decision-making. This convergence not only enhances operational efficiency but also ensures a cohesive and personalized shopping journey for consumers, bridging the gap between online and physical retail in today's interconnected marketplace.<p>",
    "subtitle2": "",
    "description2": "<p>Developing and creating our own POS system and e-commerce platform (product catalogue). Our own POS System under Mediacartz is a software and hardware combination that facilitates the transaction of goods or services between a business and its customers.</p>",
    "items": [
      {
        "title": "Eu lacinia convallis litora",
        "image": logo,
        "slug": "#"
      },
      {
        "title": "Eu lacinia convallis litora",
        "image": logo,
        "slug": "#"
      },
      {
        "title": "Eu lacinia convallis litora",
        "image": logo,
        "slug": "#"
      },
    ]
  },
  {
    "title": "TENANT MANAGEMENT SYSTEM",
    "subtitle1": "",
    "description1": "",
    "subtitle2": "",
    "description2": "<p>Helps real estate professionals using technology to manage and serve their tenants, including rental property owners, landlords, and property managers</p>",
    "items": [
      {
        "title": "Myson",
        "image": myson,
        "slug": "myson"
      }
    ]
  },
  {
    "title": "TELCO SOLUTION",
    "subtitle1": "",
    "description1": "<p>Since it was founded in 2013, our company has been a catalyst for success, working alongside partners and customers to seamlessly implement cutting-edge telecommunication and technology solutions. Our portfolio extends to VAS platforms, digital services, project management, system integrations, and beyond, providing a robust foundation for innovation and growth.</p>",
    "subtitle2": "",
    "description2": "<p>We take pride in our track record of delivering transformative solutions, driving technological evolution, and fostering strong partnerships. As we continue to evolve, our commitment to excellence remains unwavering, positioning us as a trusted leader in the dynamic landscape of telecommunications and digital services.</p>",
    "items": [
      {
        "title": "Eu lacinia convallis litora",
        "image": logo,
        "slug": "#"
      },
      {
        "title": "Eu lacinia convallis litora",
        "image": logo,
        "slug": "#"
      },
      {
        "title": "Eu lacinia convallis litora",
        "image": logo,
        "slug": "#"
      },
    ]
  }
]

export const solution_detail = 
  {
    "orca-security": {
      "category": "CYBER SECURITY",
      "title": "ORCA Security",
      "image": orca,
      "content": `
        <p><strong>Orca Security empowers you to conquer the multi-cloud maze with:</strong></p>

        <ul>
          <li value="NaN"><strong>Agentless: </strong>Orca’s Side-Scanning is a revolutionary new approach that addresses the shortcomings of agent-based solutions by collecting data from the workloads’ runtime block storage without requiring agents.</li>
          <li value="NaN"><strong>Unified Visibility:</strong>&nbsp;Ditch the dashboard deluge. Orca Security offers a single, clear view of your entire cloud environment (100% visibility), from AWS to Azure, GCP and Oracle, giving you complete situational awareness.</li>
          <li value="NaN"><strong>Proactive Defense:</strong>&nbsp;Forget reactive security; Orca Security acts as your vigilant guardian. Its AI scans your workloads for vulnerabilities, misconfigurations, and suspicious behavior, stopping threats before they breach your data.</li>
          <li value="NaN"><strong>Workload Deep Dive:</strong>&nbsp;Unlike traditional tools, Orca Security dives deeper. It analyzes runtime activity and behavior to identify anomalies and potential malicious intent, leaving no corner of your cloud unmonitored.</li>
          <li value="NaN"><strong>Effortless Deployment:</strong>&nbsp;No need for disruptive installations or complex configurations. Orca Security seamlessly integrates with your existing multi-cloud environment, letting you focus on business, not security hassles.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>EIS: Your Trusted Navigator in the Orca Ecosystem</strong></p>
        
        <p>Partnering with EIS means harnessing the power of Orca Security with the added advantage of our expertise. We'll help you:</p>
        
        <ul>
          <li value="NaN"><strong>Tailor a Solution:</strong>&nbsp;No two voyages are the same. We craft bespoke Orca Security implementations that align with your unique cloud infrastructure, needs, and budget.</li>
          <li value="NaN"><strong>Expert Deployment and Support:</strong>&nbsp;Our team guides you through every step, from initial setup to ongoing optimization, ensuring your Orca Security journey is smooth and secure.</li>
          <li value="NaN"><strong>Continuous Guidance:</strong>&nbsp;The digital seas are ever-changing. We stay abreast of evolving threats and best practices, constantly updating your Orca Security configuration to maintain impenetrable defenses.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to Set Sail?</strong></p>
        
        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how Orca Security can revolutionize your multi-cloud security.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://orca.security/ to explore further!</li>
        </ul>       
      `
    },
    "noname": {
      "category": "CYBER SECURITY",
      "title": "NoName",
      "image": noname,
      "content": `
        <p>Your APIs, the gateways to your data vault, require a vigilant guard. NoName stands guard, repelling common vulnerabilities, injection attacks, and unauthorized access. With NoName, your digital gates remain firmly shut, your data forever safe from prying eyes.</p>

        <p>&nbsp;</p>
        
        <p><strong>No More Nameless Threats: Securing Your APIs with Confidence:</strong></p>
        
        <p>Traditional security tools often leave APIs in the shadows, vulnerable and exposed. NoName Security shines a light on these hidden avenues, offering comprehensive protection you can trust:</p>
        
        <ul>
          <li value="NaN"><strong>Unmasking Vulnerabilities</strong>: NoName's AI-powered platform scans your APIs with laser precision, exposing even the most obscure vulnerabilities like injection attacks, broken authentication, and insecure configurations.</li>
          <li value="NaN"><strong>Real-time Threat Detection</strong>: Forget reactive responses. NoName acts as your vigilant lookout, continuously monitoring your APIs for suspicious activity and malicious intent, stopping threats before they infiltrate your data.</li>
          <li value="NaN"><strong>API Security Automation</strong>: Eliminate manual toil and human error. NoName automates tedious security tasks like vulnerability assessments and policy enforcement, freeing you to focus on building amazing APIs.</li>
          <li value="NaN"><strong>Effortless Integration</strong>: No need for disruptive coding or infrastructure changes. NoName seamlessly integrates with your existing API ecosystem, minimizing friction and maximizing efficiency.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>EIS: Your Expert Co-Pilot in the NoName Sky</strong></p>
        
        <p>Partnering with EIS takes your API security to new heights. We go beyond simply deploying NoName:</p>
        
        <ul>
          <li value="NaN"><strong>Bespoke Solutions</strong>: We meticulously tailor NoName to your unique API architecture, needs, and security posture, ensuring optimal protection across your digital landscape.</li>
          <li value="NaN"><strong>Implementation Expertise</strong>: Our team guides you through every step, from initial configuration to ongoing optimization, ensuring your NoName implementation is effective and sustainable.</li>
          <li value="NaN"><strong>Continuous Guidance</strong>: NoName evolves with the ever-changing threat landscape. EIS stays ahead of the curve, regularly updating your configuration and providing expert advice to maintain unwavering API security.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Don't Let Anonymous Threats Cast a Shadow Over Your API-Driven Future. </strong></p>
        
        <p>Partner with EIS and NoName today, and illuminate a path to secure and successful innovation in the vibrant digital landscape of Indonesia.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to take flight?</strong></p>
        
        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how NoName can revolutionize your Indonesian API security.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://nonamesecurity.com/ to explore further!</li>
        </ul>      
      `
    },
    "proofpoint": {
      "category": "CYBER SECURITY",
      "title": "Proofpoint",
      "image": proofpoint,
      "content": `
        <p><strong>EMAIL SECURITY: Shielding Your Inboxes with Proofpoint</strong></p>

        <p>Phishing pirates and malware marauders tremble before Proofpoint's email fortress. Advanced filtering technologies stand on the frontlines, intercepting these digital buccaneers before they reach your employees' inboxes. Proofpoint keeps your workforce cyber-savvy, minimizing data breaches and human error.</p>

        <p>&nbsp;</p>

        <p><strong>Beyond Spam Filters: Proofpoint's Multi-Layered Defense</strong></p>

        <p>Traditional email security is like a paper shield against a cannon of cyber threats. Proofpoint builds an impregnable fortress, offering a multi-layered defense that leaves no corner vulnerable:</p>

        <ul>
          <li value="NaN"><strong>Advanced Phishing Detection</strong>: Say goodbye to falling prey to clever scams. Proofpoint's AI-powered technology unmasks even the most sophisticated phishing attacks, analyzing sender behavior, language, and email content to identify and isolate threats before they reach your employees' inboxes.</li>
          <li value="NaN"><strong>Real-time Malware Neutralization</strong>: Don't let malicious attachments wreak havoc. Proofpoint scans incoming emails in real-time, neutralizing malware and zero-day threats before they infect your systems, preventing data breaches and costly downtime.</li>
          <li value="NaN"><strong>Comprehensive Data Loss Prevention</strong>: Secure your confidential information. Proofpoint monitors all outbound emails, preventing sensitive data like financial records, customer information, and intellectual property from leaving your organization, ensuring compliance with Indonesian regulations and safeguarding your most valuable assets.</li>
          <li value="NaN"><strong>Automated Threat Response</strong>: Forget manual interventions and wasted time. Proofpoint automates incident response, quickly isolating and remediating threats, minimizing damage and ensuring your business stays operational.</li>
        </ul>

        <p>&nbsp;</p>

        <p><strong>EIS: Your Proofpoint Expert in the Indonesian Landscape</strong></p>

        <p>Partnering with EIS takes your email security beyond mere protection. We become your trusted security partner, offering:</p>

        <ul>
          <li value="NaN"><strong>Bespoke Solutions</strong>: We tailor Proofpoint to your specific industry, compliance requirements, and user base, ensuring optimal protection that aligns with your Indonesian business needs.</li>
          <li value="NaN"><strong>Implementation and Optimization</strong>: Our team guides you through every step, from initial setup to ongoing optimization, ensuring your Proofpoint deployment is effective and user-friendly.</li>
          <li value="NaN"><strong>Continuous Support and Guidance</strong>: The cyber threat landscape evolves constantly. EIS stays abreast of the latest threats and best practices, updating your Proofpoint configuration and providing proactive security advice to maintain unwavering email security.</li>
        </ul>

        <p>&nbsp;</p>

        <p><strong>Don't Let Email Threats Overshadow Your Business’ Digital Voyage. </strong></p>

        <p>Partner with Exel Integrasi Solusindo and Proofpoint today, and embark on a secure and successful journey. Equip your employees with the tools and knowledge to navigate the digital seas with confidence, knowing their inboxes are shielded by the best email security in the world.</p>

        <p>&nbsp;</p>

        <p><strong>Ready to take flight?</strong></p>

        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how NoName can revolutionize your Indonesian API security.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://www.proofpoint.com/ to explore further!</li>
        </ul>

        <p>&nbsp;</p>

        <hr />
        <p>&nbsp;</p>

        <p><strong>WEB SECURITY: Beyond Firewalls, Proofpoint's Multi-Pronged Web Defense</strong></p>

        <p>Traditional web security tools are like flimsy gates against a digital siege. Proofpoint Web Security builds an impenetrable citadel, deploying a multi-pronged defense that leaves no entry point vulnerable:</p>

        <ul>
          <li value="NaN"><strong>Advanced Threat Detection</strong>: Say goodbye to blind spots. Proofpoint's AI-powered platform analyzes website traffic in real-time, instantly identifying malicious activity like zero-day exploits, SQL injection attacks, and website defacement attempts, neutralizing them before they cause damage.</li>
          <li value="NaN"><strong>DDoS Mitigation</strong>: Don't let website outages bring your business down. Proofpoint offers robust DDoS mitigation services, proactively detecting and absorbing distributed denial-of-service attacks, ensuring your Indonesian website stays online and accessible, even under heavy attack.</li>
          <li value="NaN"><strong>Vulnerability Management</strong>: Proactive defense is key. Proofpoint scans your website and web applications for vulnerabilities, identifying and patching them before cybercriminals can exploit them, minimizing risk and preventing data breaches.</li>
          <li value="NaN"><strong>Bot Management</strong>: Stop unwanted visitors in their tracks. Proofpoint identifies and blocks automated bots and crawlers, preventing spam, scraping, and unauthorized account access, enhancing your website's security and user experience.</li>
        </ul>

        <p>&nbsp;</p>

        <p><strong>EIS: Your Proofpoint Web Security Mastermind in Indonesia</strong></p>

        <p>Partnering with EIS goes beyond simply deploying Proofpoint. We become your trusted web security partner, offering:</p>

        <ul>
          <li value="NaN"><strong>Bespoke Solutions</strong>: We tailor Proofpoint to your specific website architecture, business needs, and industry regulations, ensuring optimal protection that fits your Indonesian online presence like a glove.</li>
          <li value="NaN"><strong>Implementation and Optimization</strong>: Our team guides you through every step, from initial setup to ongoing optimization, ensuring your Proofpoint implementation is effective and user-friendly.</li>
          <li value="NaN"><strong>Continuous Monitoring and Support</strong>: The cyber threat landscape is ever-changing. EIS stays vigilant, monitoring your website security 24/7 and providing proactive advice and adjustments to maintain unwavering defenses against evolving threats.</li>
        </ul>

        <p>&nbsp;</p>

        <p><strong>Don't Let Web Threats Cast a Shadow Over Your Business’ Digital Landscape</strong>. Partner with Exel Integrasi Solusindo and Proofpoint today, and build an impregnable online fortress. Protect your website, your customers, and your reputation, ensuring your Indonesian digital voyage is paved with success and unwavering security.</p>

        <p>&nbsp;</p>

        <p><strong>Ready to Build Your Web Fortress?</strong></p>

        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how Proofpoint can revolutionize your web security.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://www.proofpoint.com/ to explore further!</li>
        </ul>

        <p>&nbsp;</p>

        <hr />
        <p>&nbsp;</p>

        <p><strong>DATA LOSS PREVENTION: Guarding Your Data Crown Jewels</strong></p>

        <p>Lurking within every email, document, and cloud file lie hidden dangers: data exfiltration, accidental leaks, and malicious insider threats, waiting to steal your most prized digital assets. Fear not, data-driven innovators! Exel Integrasi Solusindo (EIS) stands as your digital guardian, wielding the impenetrable shield of Proofpoint Enterprise Data Loss Prevention (DLP), protecting your Indonesian data crown jewels from the treacherous currents of cyber theft.</p>

        <p>&nbsp;</p>

        <p><strong>Beyond Traditional Data Security: Proofpoint DLP's Proactive Defense</strong></p>

        <p>Traditional data security tools are like flimsy locks on a treasure vault, easily defeated by determined thieves. Proofpoint DLP builds an impregnable security fortress, offering a multi-layered, AI-powered defense that leaves no data channel vulnerable:</p>

        <ul>
          <li value="NaN"><strong>Intelligent Data Classification</strong>: Forget manual tagging and endless rules. Proofpoint DLP automatically identifies and classifies sensitive data like customer information, financial records, and intellectual property across all endpoints, emails, and cloud applications, ensuring comprehensive protection for your Indonesian data assets.</li>
          <li value="NaN"><strong>Real-time Data Loss Prevention</strong>: Don't wait for the damage to be done. Proofpoint DLP monitors all data transfers in real-time, detecting and blocking unauthorized exfiltration attempts, preventing confidential information from leaving your organization, regardless of the channel or endpoint.</li>
          <li value="NaN"><strong>Contextual Analysis and Detection</strong>: Forget blind data monitoring. Proofpoint DLP analyzes user behavior, content context, and recipient information, identifying suspicious activity like unusual data transfers, even if the data itself isn't explicitly tagged, ensuring no sensitive information slips through the cracks.</li>
          <li value="NaN"><strong>Continuous Policy Optimization</strong>: Don't get bogged down in static rule sets. Proofpoint DLP learns from your data usage and adapts its policies over time, minimizing false positives and streamlining data security operations, making it ideal for the dynamic data landscape of Indonesia.</li>
        </ul>

        <p>&nbsp;</p>

        <p><strong>EIS: Your Proofpoint DLP Mastermind in Indonesia</strong></p>

        <p>Partnering with EIS takes your DLP beyond mere data protection. We become your trusted data security partner, offering:</p>

        <ul>
          <li value="NaN"><strong>Bespoke Solutions</strong>: We tailor Proofpoint DLP to your specific industry regulations, data types, and business needs, ensuring optimal protection that aligns with your Indonesian compliance requirements.</li>
          <li value="NaN"><strong>Expert Implementation and Support</strong>: Our team guides you through every step, from initial deployment to ongoing optimization, ensuring your Proofpoint DLP implementation is effective, efficient, and user-friendly.</li>
          <li value="NaN"><strong>Continuous Monitoring and Guidance</strong>: The data security landscape evolves constantly. EIS stays vigilant, monitoring your data channels 24/7 and providing proactive advice and policy adjustments to maintain unwavering protection against evolving data threats in the Indonesian market.</li>
        </ul>

        <p>&nbsp;</p>

        <p><strong>Don't Let Data Threats Tarnish Your Digital Crown Jewels</strong>. Partner with Exel Integrasi Solusindo and Proofpoint DLP today, and build an impregnable data fortress. Secure your confidential information, empower your employees, and confidently unlock the immense potential of your Indonesian data assets.</p>

        <p>&nbsp;</p>

        <p><strong>Ready to Protect Your Data Crown Jewels?</strong></p>

        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how Proofpoint DLP can revolutionize your Indonesian data security.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://www.proofpoint.com/ to explore further!</li>
        </ul>

        <p>&nbsp;</p>

        <hr />
        <p>&nbsp;</p>

        <p><strong>RANSOMWARE PROTECTION: Shielding Your Data Crown Jewels from Ransomware</strong></p>

        <p>Fear not, data-driven pioneers! Exel Integrasi Solusindo (EIS) stands your digital sentinel, wielding the impenetrable shield of Proofpoint's advanced ransomware protection, safeguarding your Indonesian data crown jewels from the grip of digital extortionists.</p>

        <p>&nbsp;</p>

        <p><strong>Beyond Basic Backup Plans: Building a Multi-Layered Ransomware Defense</strong></p>

        <p>Traditional backup plans are like flimsy locks on a treasure chest, easily defeated by determined pirates. Proofpoint builds an impregnable digital fortress, offering a multi-layered defense that leaves no data entry point vulnerable:</p>

        <ul>
          <li value="NaN"><strong>Multi-Vector Detection</strong>: Forget blind spots. Proofpoint goes beyond endpoint protection, monitoring email gateways, cloud applications, and network traffic for suspicious activity, identifying even the most sophisticated ransomware strains before they reach your valuable data.</li>
          <li value="NaN"><strong>Real-time Threat Intelligence</strong>: Outsmart the pirates. Proofpoint leverages a global network of security experts and threat intelligence feeds, constantly analyzing current ransomware tactics and adapting your defenses to stay ahead of the ever-evolving threat landscape in Indonesia.</li>
          <li value="NaN"><strong>Behavioral Analysis and Anomalous Detection</strong>: Don't wait for encryption to begin. Proofpoint analyzes user behavior and system activity for unusual patterns, identifying early signs of ransomware infection before data is locked away, minimizing damage and ensuring business continuity in your Indonesian operations.</li>
          <li value="NaN"><strong>Automated Incident Response</strong>: Time is of the essence. Proofpoint offers automated incident response capabilities, isolating infected systems, containing the spread of ransomware, and minimizing downtime while your team investigates and mitigates the threat.</li>
        </ul>

        <p>&nbsp;</p>

        <p><strong>EIS: Your Proofpoint Ransomware Protection Mastermind in Indonesia</strong></p>

        <p>Partnering with EIS takes your ransomware protection beyond mere data recovery. We become your trusted security partner, offering:</p>

        <ul>
          <li value="NaN"><strong>Bespoke Solutions</strong>: We tailor Proofpoint's ransomware protection to your specific industry, data types, and Indonesian compliance requirements, ensuring optimal protection that aligns perfectly with your unique digital assets and regulatory landscape.</li>
          <li value="NaN"><strong>Expert Implementation and Support</strong>: Our team guides you through every step, from initial deployment to ongoing optimization, ensuring your Proofpoint implementation is effective, user-friendly, and seamless within your Indonesian IT infrastructure.</li>
          <li value="NaN"><strong>Continuous Monitoring and Guidance</strong>: The ransomware landscape is constantly evolving. EIS stays vigilant, monitoring your systems 24/7 and providing proactive advice and adjustments to maintain unwavering protection against emerging ransomware threats in the dynamic Indonesian market.</li>
        </ul>

        <p>&nbsp;</p>

        <p><strong>Don't Let Ransomware Pirates Hold Your Business Data Hostage</strong>. Partner with Exel Integrasi Solusindo and Proofpoint today, and build an impregnable digital fortress. Secure your confidential information, empower your employees, and confidently navigate the digital waters of Indonesia, knowing your data crown jewels are shielded by the unwavering expertise of EIS and Proofpoint advanced ransomware protection.</p>

        <p>&nbsp;</p>

        <p><strong>Ready to Safeguard Your Data Assets Against Digital Pirates?</strong></p>

        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how Proofpoint's advanced ransomware protection can revolutionize your data security.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://www.proofpoint.com/ to explore further!</li>
        </ul>

     `
    },
    "ecouncil": {
      "category": "CYBER SECURITY",
      "title": "EC-Council",
      "image": ecouncil,
      "content": `
        <p><strong>PDPL: Navigating the Labyrinth of Indonesian Data Privacy</strong></p>

        <p>Indonesia's digital realm thrives with innovation and opportunity, but as data becomes the lifeblood of businesses, navigating the ever-evolving landscape of data privacy regulations can feel like traversing a labyrinth. Fear not, data-driven pioneers! Exel Integrasi Solusindo (EIS) stands as your trusted guide, partnering with EC-Council Global Services to illuminate the path with expert data protection law advisory, ensuring your Indonesian voyage sails smoothly through the intricacies of compliance.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Beyond Compliance Boxes: Building a Culture of Data Privacy</strong></p>
        
        <p>Ticking compliance boxes is just the first step. EIS and EC-Council Global Services go beyond, empowering you to build a robust data privacy culture within your organization:</p>
        
        <ul>
          <li value="NaN"><strong>Clarifying the Maze of Indonesian Regulations</strong>: Forget baffling legal jargon. Our experts decode complex Indonesian data privacy laws like UU PDP (Personal Data Protection Law) and GDPR, translating them into practical guidance and actionable steps for your business.</li>
          <li value="NaN"><strong>Risk Assessments and Gap Analyses</strong>: Leave no blind spots undiscovered. We conduct comprehensive risk assessments and gap analyses, identifying vulnerabilities and discrepancies between your practices and Indonesian data privacy regulations, charting a clear path towards compliance.</li>
          <li value="NaN"><strong>Tailored Policy and Procedure Development</strong>: Forget generic templates. We collaborate with you to craft bespoke data privacy policies and procedures that perfectly fit your Indonesian business operations, ensuring data governance that aligns with your unique needs and legal obligations.</li>
          <li value="NaN"><strong>Employee Training and Awareness</strong>: Knowledge is power. We provide engaging and customized training programs for your team, raising awareness about data privacy regulations, best practices, and individual responsibilities within your organization, fostering a culture of data protection.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>EIS: Your Indonesian Data Privacy Lighthouse with EC-Council's Expertise</strong></p>
        
        <p>Partnering with EIS unlocks EC-Council's global expertise, giving you the edge in navigating the Indonesian data privacy landscape:</p>
        
        <ul>
          <li value="NaN"><strong>Accredited Professionals</strong>: EC-Council’s team boasts certified data privacy professionals ranging from Certified Information Systems Security Professional (CISSP), Offensive Security Certified Expert (OSCE), Offensive Security Certified Professional (OSCP), and Certified Information Systems Auditor (CISA), equipped with the knowledge and experience to guide you through the most intricate legal nuances.</li>
          <li value="NaN"><strong>Global Perspective, Local Understanding</strong>: EC-Council's extensive global network ensures you benefit from cutting-edge data privacy insights from around the world, while EIS provides deep local expertise, guaranteeing your solutions are grounded in the specific context of Indonesian regulations.</li>
          <li value="NaN"><strong>Continuous Support and Guidance</strong>: The data privacy landscape is constantly evolving. We stay ahead of the curve, providing ongoing support and guidance as regulations change, ensuring your Indonesian business remains compliant and adapts to future data protection requirements.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Don't Let Data Privacy Concerns Impede Your Business’ Digital Voyage</strong>. Partner with Exel Integrasi Solusindo and EC-Council Global Services today, and illuminate your path towards compliance and data protection excellence. Navigate the complex labyrinth of Indonesian data privacy regulations (PDPL) with confidence, ensuring your digital journey is powered by trust, transparency, and unwavering security.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to Set Sail Towards Data Privacy Compliance?</strong></p>
        
        <ol>
          <li value="NaN">Contact EIS for a free consultation and discover how EC-Council Global Services can empower your business to thrive in the age of data privacy.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://www.eccouncil.org/ to explore further!</li>
        </ol>
        
        <p>&nbsp;</p>
        
        <hr />
        <p>&nbsp;</p>
        
        <p><strong>CYBERSECURITY ADVISORY: Navigating the Indonesian Digital Labyrinth</strong></p>
        
        <p>Navigating this labyrinth alone can be perilous, leaving your business vulnerable to data breaches, reputational damage, and operational disruptions. Fear not, intrepid explorers! Exel Integrasi Solusindo (EIS) stands as your trusted guide, partnering with EC-Council Global Services to unveil the hidden security dangers and illuminate the path towards a secure and resilient digital future for your Indonesian voyage.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Beyond Compliance Checkboxes: Building a Culture of Cyber Resilience</strong></p>
        
        <p>Ticking compliance boxes and installing basic security tools are just the first steps. EIS and EC-Council Global Services go beyond, empowering you to build a robust cyber-resilient culture within your Indonesian organization:</p>
        
        <ul>
          <li value="NaN"><strong>Comprehensive Cybersecurity Assessments</strong>: Gain clear insights into your vulnerabilities. Our experts conduct thorough vulnerability assessments, penetration testing, and security audits, identifying weaknesses in your infrastructure, policies, and practices, providing a roadmap for improvement tailored to your specific Indonesian context.</li>
          <li value="NaN"><strong>Risk Management and Mitigation Strategies</strong>: Don't let risks lurk in the shadows. We help you prioritize identified vulnerabilities, develop effective risk management strategies, and implement practical mitigations, minimizing your exposure to cyber threats in the dynamic Indonesian digital ecosystem.</li>
          <li value="NaN"><strong>Tailored Security Policy and Procedure Development</strong>: Forget generic templates. We collaborate with you to craft bespoke security policies and procedures that perfectly align with your Indonesian operations, legal obligations, and industry best practices, ensuring comprehensive protection for your business.</li>
          <li value="NaN"><strong>Employee Training and Awareness Programs</strong>: Knowledge is power. EC-Council's renowned training programs, delivered by EIS instructors, equip your team with the awareness, skills, and best practices needed to identify and address cyber threats within their daily activities, fostering a culture of security throughout your Indonesian organization.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>EIS: Your Cyber-Resilience Lighthouse with EC-Council's Global Expertise</strong></p>
        
        <p>Partnering with EIS unlocks the global expertise of EC-Council Global Services, giving you a distinct advantage in navigating the Indonesian digital labyrinth:</p>
        
        <ul>
          <li value="NaN"><strong>Accredited Security Professionals</strong>: EC-Council’s team boasts certified consultants and ethical hackers like Certified Information Systems Security Professionals (CISSPs) and Certified Ethical Hackers (CEHs), equipped with the knowledge and experience to tackle even the most sophisticated cyber threats in the Indonesian market.</li>
          <li value="NaN"><strong>International Standards and Best Practices</strong>: Leverage our global perspective. We draw upon EC-Council's extensive understanding of international security standards and best practices, ensuring your Indonesian business remains compliant and secure regardless of your operating environment.</li>
          <li value="NaN"><strong>Continuous Support and Guidance</strong>: The cyber landscape is constantly evolving. We stay ahead of the curve, providing ongoing support and guidance as regulations change and new threats emerge, ensuring your Indonesian business remains resilient and adapts to future cybersecurity challenges.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Don't Let Cyber Uncertainties Impede Your Business’ Digital Voyage</strong>. Partner with Exel Integrasi Solusindo and EC-Council Global Services today, and illuminate your path towards cyber resilience. Embrace the expertise of certified consultants and global cybersecurity knowledge to navigate the digital labyrinth with confidence, leaving uncertainties behind and powering your Indonesian journey with unwavering security and compliance.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to Set Sail Towards a Cyber-Resilient Future?</strong></p>
        
        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how EC-Council Global Services can empower your business to thrive in the age of digital security.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://www.eccouncil.org/ to explore further!</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <hr />
        <p>&nbsp;</p>
        
        <p><strong>CYBERSECURITY TRAINING: Empowering Your Indonesian Workforce</strong></p>
        
        <p>To navigate this landscape with confidence, your most valuable asset is your people—a skilled and vigilant workforce equipped to safeguard your digital fortress. Fear not, digital pioneers! Exel Integrasi Solusindo (EIS) stands as your trusted partner, wielding the unparalleled training expertise of EC-Council to empower your Indonesian team with world-class cybersecurity skills, transforming them into digital guardians and architects of a secure future.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Beyond Basic Awareness: Building Cyber-Resilient Teams</strong></p>
        
        <p>Traditional cybersecurity training often falls short, leaving knowledge gaps and vulnerabilities exposed. EIS and EC-Council go beyond, offering comprehensive programs that cultivate cyber-resilient teams:</p>
        
        <ul>
          <li value="NaN"><strong>Industry-Leading Curriculum</strong>: Forget generic content. We offer a diverse portfolio of EC-Council courses, from foundational certifications like Certified Ethical Hacker (CEH) to specialized tracks in incident response, cloud security, and digital forensics, ensuring your training aligns with your specific Indonesian business needs and cyber risks.</li>
          <li value="NaN"><strong>Hands-on Experience</strong>: Knowledge without practice is like a shield without a sword. We provide immersive labs and simulations, allowing your team to test their skills, hone their reflexes, and confidently respond to real-world cyber threats in the Indonesian digital terrain.</li>
          <li value="NaN"><strong>Customizable Training Solutions</strong>: One size doesn't fit all. We collaborate with you to design tailored training programs, addressing your specific skill gaps, industry regulations, and operational workflow, ensuring your Indonesian workforce receives the most relevant and impactful cybersecurity training.</li>
          <li value="NaN"><strong>Expert Instructors and Mentorship</strong>: Learn from the best. Our team boasts certified EC-Council instructors and industry veterans, ready to share their knowledge, inspire your team, and provide ongoing mentorship, ensuring continuous learning and growth within your Indonesian talent pool.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Don't let cyber threats hold your business digital voyage hostage</strong>. Partner with Exel Integrasi Solusindo and EC-Council today, and ignite your workforce with the power of world-class cybersecurity training. Empower your team to become proactive defenders, architects of a secure future, and catalysts for your digital success in Indonesia.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to Transform Your Workforce into Cyber Heroes?</strong></p>
        
        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how EC-Council training can empower your team to navigate the digital landscape with confidence.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://www.eccouncil.org/ to explore further!</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <hr />
        <p>&nbsp;</p>
        
        <p><strong>SOC-as-a-SERVICE: Your Watchtower in the Indonesian Digital Jungle</strong></p>
        
        <p>To navigate this vibrant yet perilous landscape, your business needs a vigilant observer in the watchtower – a Security Operations Center (SOC) that never sleeps, constantly scanning the horizon for danger. Fear not, intrepid explorers! Exel Integrasi Solusindo (EIS) stands as your stalwart defender, partnering with EC-Council Global Services to offer SOC-as-a-Service, an ever-watchful eye safeguarding your Indonesian digital journey.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Beyond DIY SOCs: Unveiling the Power of a Managed Watchtower</strong></p>
        
        <p>Building and maintaining your own SOC in Indonesia can be a complex and resource-intensive task. EIS and EC-Council Global Services break free from these limitations, offering:</p>
        
        <ul>
          <li value="NaN"><strong>24/7 Threat Detection and Response</strong>: Sleep soundly knowing your defenses are always on guard. Our team of cybersecurity experts, leveraging EC-Council's expertise, tirelessly monitors your Indonesian infrastructure, analyzing events, detecting anomalies, and neutralizing threats before they can infiltrate your systems.</li>
          <li value="NaN"><strong>Advanced Threat Intelligence</strong>: Know your enemy. We go beyond basic alert monitoring, utilizing cutting-edge threat intelligence feeds and predictive analytics to anticipate emerging threats and proactively adapt your defenses, keeping you ahead of the curve in the ever-evolving Indonesian cyber landscape.</li>
          <li value="NaN"><strong>Incident Response Expertise</strong>: When threats slip through, we have your back. Our certified incident responders, guided by EC-Council's best practices, quickly contain and remediate security incidents, minimizing damage and ensuring business continuity in your Indonesian operations.</li>
          <li value="NaN"><strong>Compliance Confidence</strong>: Navigate Indonesian regulations with ease. Our SOC-as-a-Service adheres to rigorous security standards and compliance frameworks, helping you stay compliant with data privacy laws and industry regulations, building trust and safeguarding your reputation in Indonesia.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>EIS: Your SOC Partner with EC-Council's Global Advantage</strong></p>
        
        <p>Partnering with EIS brings the global expertise of EC-Council to your Indonesian SOC:</p>
        
        <ul>
          <li value="NaN"><strong>Certified Security Professionals</strong>: EC-Council’s team boasts certified EC-Council experts like Certified Secure Incident Responders (CSIRs) and Certified Ethical Hackers (CEHs), equipped with the knowledge and experience to tackle even the most sophisticated cyber threats in the Indonesian digital ecosystem.</li>
          <li value="NaN"><strong>Global Threat Intelligence Network</strong>: Leverage our global network of security analysts and threat researchers. You gain access to real-time insights and insights into emerging threats tailored to the Indonesian context, ensuring your defenses are always up-to-date and effective.</li>
          <li value="NaN"><strong>Scalability and Adaptability</strong>: As your Indonesian business grows, your SOC needs to keep pace. EIS and EC-Council Global Services offer a flexible and scalable service, adapting to your evolving needs and ensuring your defenses remain robust and relevant.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Don't Let Cyber Threats Turn Your Business’ Digital Voyage into an Unsafe Journey</strong>. Partner with Exel Integrasi Solusindo and EC-Council Global Services today, and secure your digital watchtower with SOC-as-a-Service. Trust our vigilant eyes to safeguard your Indonesian operations, allowing you to explore the digital jungle with confidence, knowing your journey is protected by the expertise of EIS and EC-Council.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to Secure Your Business Digital Fortress?</strong></p>
        
        <ul>
          <li value="NaN">Contact EIS for a free consultation and discover how EC-Council Global Services SOC-as-a-Service can empower your Indonesian business to thrive in the digital age.</li>
          <li value="NaN">Dive into more knowledge? Just click: https://www.eccouncil.org/ to explore further!</li>
        </ul>      
      `
    },
    "mediacartz": {
      "category": "ADVERTISING & CUSTOMER ENGAGEMENT",
      "title": "Mediacartz",
      "image": mediacartz,
      "content": `
        <p>Effective advertising is not only about promoting a product or service; it&#39;s a dynamic tool for fostering customer engagement. In today&#39;s competitive market, successful advertising goes beyond traditional methods, encompassing digital channels, social media, and personalized experiences.</p>

        <p>&nbsp;</p>
        
        <p>Engaging customers requires a deep understanding of their needs and preferences, allowing businesses to tailor their messages and offerings. The advent of data-driven marketing has further revolutionized customer engagement, enabling targeted campaigns that resonate with specific demographics.</p>
        
        <p>&nbsp;</p>
        
        <p>To build lasting connections, businesses must prioritize authenticity, transparency, and a customer-centric approach in their advertising strategies, fostering trust and loyalty that extends beyond the initial transaction.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Mediacartz: Simplify Campaigns Across All Customers Touchpoints</strong></p>
        
        <p>An integrated campaign management platform, is a comprehensive and centralized system designed to streamline and optimize the planning, execution, and analysis of marketing campaigns across various channels. This platform typically combines a suite of tools and features to facilitate coordination and collaboration among marketing teams.</p>
        
        <p>&nbsp;</p>
        
        <p>Key functionalities often include campaign planning and scheduling, content creation and management, audience segmentation, multi-channel distribution (such as email, social media, and advertising), analytics and reporting, and performance tracking. By providing a unified environment, an integrated campaign management platform allows marketers to have a holistic view of their campaigns, ensuring consistent messaging and branding while efficiently managing resources and maximizing the impact of marketing efforts.</p>
        
        <p>&nbsp;</p>
        
        <p>This type of platform is crucial for organizations seeking to orchestrate complex marketing campaigns across diverse channels in a cohesive and data-driven manner.</p>
        
        <p>&nbsp;</p>
        
        <p>Our platform enables Customers/Advertisers to reach broader audiences, and allow Publishers to monetize web traffic with minimal effort.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Mediacartz: All You Need to Know About the Overall Insights of Your Campaign</strong></p>
        
        <ul>
          <li><strong>Advertising Dahsboard</strong>: Provide users a multi-platform (omni-channels) to deliver the ads/messages to the end-customers, based on profiles and needs (Text Message, WhatsApp Messaging, DOOH Media, TV Commercial, Email Blast, Online &amp; Social Media.)</li>
          <li><strong>Event Management</strong>: Entrust your event with Mediacartz team, enhances organizational efficiency, optimizes resource allocation, and ensures a more engaging and successful event experience for both organizers and attendees.</li>
          <li><strong>Eventories</strong>: Allows users to browse, create, and promote their events.</li>
          <li><strong>Product Catalogue</strong>: Provide as an add-on services for users to display their products/services. Users can also do transaction with the customers directly through this service.</li>
          <li><strong>Publisher Aggregator</strong>: Building collaboration with all the media owners (publishers) to be part of Mediacartz platform.</li>
          <li><strong>Customer Profile &amp; CRM</strong>: Manage data within the Mediacartz dashboard (based on: campaigns, telco data, client behavior, and events).</li>
          <li><strong>Report</strong>: Provide an integrated and unified report of users advertising and promotion program.</li>
          <li><strong>POS System</strong>: Facilitates the transaction of goods or services between a business and its customers.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to Create Campaign and Reach Broader Audience for Your Business?</strong></p>
        
        <ul>
          <li>Contact EIS for a free consultation and discover how Mediacartz can empower your business and to be seen by your target audience.</li>
          <li>Dive into more knowledge? Just click: https://www.mediacartz.com/ to explore further!</li>
        </ul>      
      `
    },
    "sobot": {
      "category": "ADVERTISING & CUSTOMER ENGAGEMENT",
      "title": "Sobot",
      "image": sobot,
      "content": `
        <p>The significance of customer full-lifecycle service lies in its ability to create a cohesive and positive experience for customers at every stage of their journey with a business. By offering comprehensive support from initial engagement to post-purchase interactions, businesses can build trust, foster loyalty, and enhance overall customer satisfaction.</p>

        <p>&nbsp;</p>
        
        <p>In today&#39;s competitive landscape, prioritizing customer full-lifecycle service is a strategic imperative for businesses aiming to differentiate themselves and establish strong, lasting relationships with their customer base.</p>
        
        <p>&nbsp;</p>
        
        <h2><strong>Sobot</strong></h2>
        
        <p>&nbsp;</p>
        
        <p>Providing a new generation of customer full-lifecycle service solutions, and use artificial intelligence technology to help companies reduce costs, increase efficiency, increase sales, and improve user satisfaction.</p>
        
        <p>&nbsp;</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Sobot: An Intelligent Customer Contact Solution</strong></p>
        
        <ul>
          <li>Products:
          <ol style="list-style-type:lower-alpha">
            <li>Voice: Inbound | Outbound | Global Number</li>
            <li>Live Chat: Omni-channel | Unified Workspace | API</li>
            <li>Chatbot: 24/7 | Multilingual | ChatGPT</li>
            <li>Ticketing System: Collaborative | Auto Trigger | SLA</li>
          </ol>
          </li>
          <li>Solutions:
          <ol style="list-style-type:lower-alpha">
            <li>E-C Retail: Great CX Driving Revenue Growth</li>
            <li>Financial Services: Safe System and Better Experiences</li>
            <li>Omni-channel &amp; AI: Engage Customers Across Every Channel</li>
          </ol>
          </li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to Give a Better Overall Customer Satisfaction?</strong></p>
        
        <ul>
          <li>Contact EIS for a free consultation and discover how Sobot can improve user satisfaction by using Artificial Technology.</li>
          <li>Dive into more knowledge? Just click: https://www.sobot.io/ to explore further!</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Outsourcing &amp; Talent Management</strong></p>
        
        <p>&nbsp;</p>
        
        <p>Outsourcing task management systems provide businesses with a streamlined and efficient approach to handling various responsibilities by delegating tasks to external service providers. These systems leverage technology to facilitate seamless communication, collaboration, and tracking of delegated assignments, ensuring that both the outsourcing company and the external team are on the same page regarding project progress and milestones.</p>
      
      `
    },
    "phoenix": {
      "category": "OUTSOURCING & TALENT MANAGEMENT",
      "title": "Phoenix",
      "image": phoenix,
      "content": `
        <p>It was all started in 2018, when PT EIS founded a company under the name of 3G Indonesia (PT Kairos Cahaya Cemerlang). Starting its business as an Event Management and Talent Management which earns the trust from various sectors, both private and government.</p>

        <p>&nbsp;</p>
        
        <p>In 2019, 3G Indonesia (PT Kairos Cahaya Cemerlang) started to diversify its business, not only as an Event Management and Talent Management, but tapping into the Outsourcing Management Agency, providing professional workforce for sales purposes, promotion and marketing, and product inventory, which serves organization/retail to help them maximize their business process.</p>
        
        <p>&nbsp;</p>
        
        <p>Then using EIS technology expertise, at the same year in 2019, Exel Integrasi Solusindo (EIS) developed an App for outsourcing &amp; talent management industry called Phoenix, answering the needs of the customer for making the outsourcing tasks easier, simpler and real-time reporting, hence reducing the fraud which could be done in the field.</p>      
      `
    },
    "wais": {
      "category": "OUTSOURCING & TALENT MANAGEMENT",
      "title": "Wais",
      "image": wais,
      "content": `
        <p>Based on insights in 2023, the ever-growing demand for business support services from numerous companies, coupled with advancements in technology and IT infrastructure, holds the promise of enhancing the efficiency and cost-effectiveness of outsourcing businesses. This is what we call &ldquo;The Outsourcing 5.0 Era&rdquo;.</p>

        <p>&nbsp;</p>
        
        <p>Keeping this in mind, EIS are excited to introduce the evolution of 3G Indonesia and Phoenix in 2024 &ndash; &quot;WAIS&quot; (Workforce Automation Intelligence Services) by EIS. WAIS emerges as a comprehensive solution, offering integrated outsourcing services tailored to address your challenges in talent outsourcing (SPG, Merchandiser, etc.) and other business processing outsourcing needs. This innovative system is designed to streamline and enhance efficiency, providing an all-encompassing solution within a single, cohesive framework.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>WAIS</strong></p>
        
        <p><strong>Enhances Activities for Greater Efficiency and Effectiveness</strong></p>
        
        <ol>
          <li>Intuitive user interface (UI) design for easy use by field teams and principals using smart phones (WAIS Mobile Apps) and Dashboard (WAIS Dashboard)</li>
          <li>Able to track the movement of inventory in real-time.</li>
          <li>Capable of integrating features for monitoring employee activities, including location and completed tasks.</li>
          <li>There is a notification system to inform the principal about important updates, such as changes in the status of goods (inventory) or sales target achievements.</li>
          <li>Additionally, there is a feature to generate sales reports automatically and can be accessed anytime.</li>
          <li>Payment technology services integrated system.</li>
          <li>Seamlessly manage funds, encompassing payroll, reimbursement, and various other work activities.</li>
        </ol>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to be Part of the Outsourcing 5.0 Era?</strong></p>
        
        <ul>
          <li>Contact EIS for a free consultation and discover how WAIS can provide an all-encompassing solution within a single, cohesive framework.</li>
        </ul>      
      `
    },
    "myson": {
      "category": "TENANT MANAGEMENT SYSTEM",
      "title": "Myson",
      "image": myson,
      "content": `
        <p>A platform designed to streamline and automate the management of rental properties and the interactions between landlords or property managers and their tenants. This system is particularly useful for property management companies, real estate professionals, or individual landlords who want to efficiently oversee multiple rental units.</p>

        <p>&nbsp;</p>
        
        <p><strong>Myson: Integrated Workplace Management System (IWMS) </strong></p>
        
        <p>An enterprise-scale software platform that enables executives and managers to reduce these costs significantly and increase business productivity at the same time.</p>
        
        <p>&nbsp;</p>
        
        <p><strong>Key points of Myson Solution:</strong></p>
        
        <ul>
          <li><strong>Tenant Information Database</strong>: Centralized storage of tenant details, lease agreements, contact information, and historical data.</li>
          <li><strong>Lease Management</strong>: Tracking lease agreements, renewal dates, and terms, while automating rent calculations and adjustments.</li>
          <li><strong>Rent Payment Processing</strong>: Providing a platform for tenants to make online rent payments securely, and facilitating automated reminders for due dates.</li>
          <li><strong>Maintenance Requests</strong>: Allowing tenants to submit maintenance requests online, and enabling property managers to efficiently track, assign, and resolve these requests.</li>
          <li><strong>Communication Tools</strong>: Facilitating communication between landlords or property managers and tenants through messaging or notification features.</li>
          <li><strong>Document Management</strong>: Storing important documents such as lease agreements, property inspection reports, and notices in a centralized and secure repository.</li>
          <li><strong>Financial Tracking</strong>: Monitoring financial aspects, including rent collection, expenses, and generating financial reports for property owners or accounting purposes.</li>
          <li><strong>Tenant Portals</strong>: Providing tenants with a secure portal where they can access important information, make payments, and submit requests.</li>
          <li><strong>Reporting and Analytics</strong>: Generating reports on various aspects of property management, including financial performance, occupancy rates, and maintenance trends.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong>Ready to Streamline and Automate Your Property Management?</strong></p>
        
        <ul>
          <li>Contact EIS for a free consultation and discover how Myson can enable a more sustainable property management.</li>
          <li>Dive into more knowledge? Just click: https://myson.com.my/ to explore further!</li>
        </ul>      
      `
    },
    "dummy": {
      "category": "",
      "title": "",
      "image": "",
      "content": `
      `
    }
  }
